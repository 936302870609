import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import Home from '@/views/pages/Home/index.vue'
import Result from '@/views/pages/Result/index.vue'
import Dashboard from '@/views/pages/Dashboard/index.vue'
import Subscribers from '@/views/pages/Subscribers/index.vue'
import SubscriberSlug from '@/views/pages/Subscriber/_slug'
import SubscriberPresentation from '@/views/pages/Subscriber/Presentation'
import SubscriberDocuments from '@/views/pages/Subscriber/Documents'
import Suppliers from '@/views/pages/Suppliers/index.vue'
import SuppliersSlug from '@/views/pages/Supplier/_slug'
import SupplierPresentation from '@/views/pages/Supplier/Presentation'
import SupplierOffers from '@/views/pages/Supplier/Offers'
import SupplierDocuments from '@/views/pages/Supplier/Documents'
import SupplierCards from '@/views/pages/Supplier/Cards'
import Offers from '@/views/pages/Offers/index.vue'
import Documents from '@/views/pages/Documents/index.vue'
import DocumentFile from '@/views/pages/Document/file/index.vue'
import Staff from '@/views/pages/Staff/index.vue'
import Toolbox from '@/views/pages/Toolbox/index.vue'
import News from '@/views/pages/News/index.vue'
import Articles from '@/views/pages/Articles/index.vue'
import ArticleSlug from '@/views/pages/Article/_slug.vue'
import Formations from '@/views/pages/Formations/index.vue'
import Formation from '@/views/pages/Formation/_slug.vue'
import Login from '@/views/authentication/Login.vue'
import Profile from '@/views/pages/Profile/index.vue'
import Form from '@/views/pages/Form/index.vue'
import PageSlug from '@/views/pages/Page/_slug.vue'
import Indexes from '@/views/pages/Indexes/index.vue'
import Roadmap from '@/views/pages/Roadmap/index.vue'
import IndicesListing from '@/views/pages/Indices/index.vue'
import IndiceDetail from '@/views/pages/Indice/_id.vue'
import NotFound from '@/views/errors/404.vue'
import AccessDenied from '@/views/errors/403.vue'
import Cookies from "js-cookie"

Vue.use(VueRouter)

const routes = [
  // Page d'accueil
  {
    path: '/',
    name: 'home',
    component: Home,
    // component: () => import('../views/pages/Home/index.vue'),
    meta: {
      title: 'Accueil - Mercurial'
    }
  },

  // Page résultat de la recherche
  {
    path: '/recherche',
    name: 'result',
    component: Result,
    // component: () => import('../views/pages/Result/index.vue'),
    meta: {
      requiresAuth: true,
      hasDrawer: true,
      hasAlphabetizer: true,
      hasCheckboxes: true,
      hasMostWanted: true,
      title: 'Résultats de la recherche - Mercurial'
    }
  },

  // Dashboards
  {
    path: '/dashboard',
    component: Dashboard,
    // component: () => import('../views/pages/Dashboard/index.vue'),
    meta: {
      title: 'Tableau de bord - Mercurial'
    },
    children: [
      {
        path: '',
        name: 'dashboard',
        beforeEnter: (to, from, next) => {
          if (!to.query.tab) {
            next({ path: '/dashboard', query: { tab: 'month' }, replace: true });
          } else {
            next();
          }
        }
      },
      {
        path: ':slug',
        name: 'dashboard-slug',
        beforeEnter: (to, from, next) => {
          if (!to.query.tab) {
            next({ path: to.path, query: { tab: 'month' }, replace: true });
          } else {
            next();
          }
        }

      }
    ],
  },

  // Page de la liste des adhérents
  {
    path: '/adherents',
    name: 'subscribers',
    component: Subscribers,
    // component: () => import('../views/pages/Subscribers/index.vue'),
    meta: {
      requiresAuth: true,
      hasDrawer: true,
      // hasAlphabetizer: true,
      hasFavorite: true,
      hasCheckboxes: true,
      hasFilters: true,
      title: 'Liste des adhérents - Mercurial'
    }
  },

  // Page résultats adhérents
  {
    path: '/recherche/adherents',
    name: 'subscribersSearch',
    component: Subscribers,
    // component: () => import('../views/pages/Subscribers/index.vue'),
    meta: {
      requiresAuth: true,
      hasDrawer: true,
      // hasAlphabetizer: true,
      hasFavorite: true,
      hasCheckboxes: true,
      hasFilters: true,
      title: 'Résultats adhérents - Mercurial'
    }
  },

  // Page d'affichage d'un adhérent
  {
    path: '/adherent/:slug',
    name: 'subscriber',
    component: SubscriberSlug,
    // component: () => import('../views/pages/Subscriber/_slug'),
    meta: {
      hasDrawer: true,
      hasNavigation: true,
      title: 'Fiche adhérent - Mercurial'
    },

    children: [
      {
        path: '',
        name: 'subscriberPresentation',
        component: SubscriberPresentation,
        // component: () => import('../views/pages/Subscriber/Presentation'),
        meta: {
          hasDrawer: true,
          hasNavigation: true
        }
      },
      {
        path: 'documents',
        name: 'subscriberDocuments',
        component: SubscriberDocuments,
        // component: () => import('../views/pages/Subscriber/Documents'),
        meta: {
          hasDrawer: true,
          hasNavigation: true
        }
      }
    ]
  },

  // Liste des fournisseurs avec possibilité de filtrer
  {
    path: '/fournisseurs',
    name: 'suppliers',
    component: Suppliers,
    // component: () => import('../views/pages/Suppliers/index.vue'),
    meta: {
      hasDrawer: true,
      hasCheckboxes: true,
      // hasAlphabetizer: true,
      title: 'Résultats fournisseurs - Mercurial'
    }
  },

  {
    path: '/fournisseurs/:slug',
    name: 'suppliers',
    component: Suppliers,
    // component: () => import('../views/pages/Suppliers/index.vue'),
    meta: {
      hasDrawer: true,
      hasCheckboxes: true,
      // hasAlphabetizer: true,
      title: 'Résultats fournisseurs - Mercurial'
    }
  },

  // Page d'affichage d'un fournisseur
  {
    path: '/fournisseur/:slug',
    component: SuppliersSlug,
    name: 'supplier',
    // component: () => import('../views/pages/Supplier/_slug'),
    meta: {
      hasDrawer: true,
      hasNavigation: true,
      hasEvaluation: true,
      title: 'Fiche fournisseur - Mercurial'
    },

    children: [
      {
        path: '',
        name: 'supplierPresentation',
        component: SupplierPresentation,
        // component: () => import('../views/pages/Supplier/Presentation'),
        meta: {
          hasDrawer: true,
          hasNavigation: true,
          hasEvaluation: true
        }
      },
      {
        path: 'offres',
        name: 'supplierOffers',
        component: SupplierOffers,
        // component: () => import('../views/pages/Supplier/Offers'),
        meta: {
          hasDrawer: true,
          hasNavigation: true,
          hasEvaluation: true
        }
      },
      {
        path: 'documents',
        name: 'supplierDocuments',
        component: SupplierDocuments,
        // component: () => import('../views/pages/Supplier/Documents'),
        meta: {
          hasDrawer: true,
          hasNavigation: true,
          hasEvaluation: true
        }
      },
      {
        path: 'fiches',
        name: 'supplierCards',
        component: SupplierCards,
        // component: () => import('../views/pages/Supplier/Cards'),
        meta: {
          hasDrawer: true,
          hasNavigation: true,
          hasEvaluation: true
        }
      }
    ]
  },

  // Résultat de recherche des offres
  {
    path: '/offres',
    name: 'offers',
    component: Offers,
    // component: () => import('../views/pages/Offers/index.vue'),
    meta: {
      hasDrawer: true,
      // hasAlphabetizer: true,
      title: 'Résultats des offres - Mercurial'
    }
  },

  // Résultat de recherches documentaire
  {
    path: '/documents',
    name: 'documents',
    component: Documents,
    // component: () => import('../views/pages/Documents/index.vue'),
    meta: {
      hasDrawer: true,
      // hasAlphabetizer: true,
      hasFilters: true,
      title: 'Résultats des documents - Mercurial'
    },
  },

  {
    path: '/documents/:slug/file',
    name: 'DocumentFile',
    component: DocumentFile,
    meta: {
      hasDrawer: false,
      title: 'Fichier'
    },
  },

  // Page de l'équipe
  {
    path: '/mercurial',
    name: 'staff',
    component: Staff,
    // component: () => import('../views/pages/Staff/index.vue'),
    meta: {
      title: 'L\'équipe - Mercurial'
    }
  },

  // Page boîte à outils
  {
    path: '/boite-a-outils',
    name: 'toolbox',
    component: Toolbox,
    // component: () => import('../views/pages/Toolbox/index.vue'),
    meta: {
      title: 'Boîte à outils - Mercurial'
    }
  },

  // Page du fil d'actualité
  {
    path: '/actualites',
    name: 'news',
    component: News,
    // component: () => import('../views/pages/News/index.vue'),
    meta: {
      title: 'Fil d\'actualités - Mercurial'
    }
  },

  // Page des articles
  {
    path: '/articles',
    name: 'articles',
    component: Articles,
    // component: () => import('../views/pages/Articles/index.vue'),
    meta: {
      title: 'Fil d\'actualités - Mercurial'
    }
  },

  // Page d'un article
  {
    path: '/article/:slug',
    name: 'article',
    component: ArticleSlug,
    // component: () => import('../views/pages/Article/_slug.vue'),
    meta: {
      title: 'Mercurial'
    }
  },

  // Page des formations
  {
    path: '/formations',
    name: 'formations',
    component: Formations,
    // component: () => import('../views/pages/Formations/index.vue'),
    meta: {
      title: 'Formations - Mercurial'
    }
  },

  // / Page d'une formation
  {
    path: '/formation/:slug',
    name: 'formation',
    component: Formation,
    // component: () => import('../views/pages/Formation/_slug.vue'),
    meta: {
      title: 'Formation - Mercurial'
    }
  },

  // Page d'authentication
  {
    path: '/login',
    name: 'login',
    component: Login,
    // component: () => import('../views/authentication/Login.vue'),
    meta: {
      layout: 'blank',
      title: 'Connexion - Mercurial'
    }
  },

  // Page du profil de l'user
  {
    path: '/profil',
    name: 'profile',
    component: Profile,
    // component: () => import('../views/pages/Profile/index.vue'),
    meta: {
      title: 'Profil - Mercurial'
    }
  },


  // Page de feuille de route
  {
    path: '/roadmap',
    name: 'roadmap',
    component: Roadmap,
    meta: {
      title: 'Feuille de route - Mercurial',
      hasDrawer: true,
      hasFilters: true,
    }
  },

  // Page de contact
  {
    path: '/formulaire',
    name: 'form',
    component: Form,
    // component: () => import('../views/pages/Form/index.vue'),
    meta: {
      title: 'Formulaire - Mercurial'
    }
  },

  // Page custom générée par Drupal
  {
    path: '/page/:slug',
    name: 'page',
    component: PageSlug
    // component: () => import('../views/pages/Page/_slug.vue')
  },

  // Page des indices macro-économiques
  /*{
    path: '/indices',
    name: 'indexes',
    component: Indexes
    // component: () => import('../views/pages/Indexes/index.vue')
  },*/

  // Page listing indices
  {
    path: '/indices',
    name: 'indices-listing',
    component: IndicesListing,
    meta: {
      hasDrawer: true,
    }
  },

  // Page detail d'un indice'
  {
    path: '/indices/:id',
    name: 'indice-detail',
    component: IndiceDetail,
    meta: {
      hasDrawer: true,
    }
  },

  // Page de la feuille de route
  // {
  //   path: '/feuille-de-route',
  //   name: 'roadmap',
  //   component: Roadmap,
  //   // component: () => import('../views/pages/Roadmap/index.vue'),
  //   meta: {
  //     title: 'Feuille de route - Mercurial'
  //   }
  // },

  // Page de saisie du CA
  {
    path: '/edeal',
    name: 'E-deal',
    beforeEnter () { location.href = 'https://crm.e-deal.net/mercurialPortal/login.jsp?forwardPage=%2Flist_my_declarationca_.fl' }
  },

  // Page 404
  {
    path: '/404',
    component: NotFound,
    // component: () => import('../views/errors/404.vue'),
    meta: {
      title: 'Introuvable - Mercurial'
    }
  },
  // Page 403
  {
    path: '/403',
    component: AccessDenied,
    // component: () => import('../views/errors/404.vue'),
    meta: {
      title: 'Introuvable - Mercurial'
    }
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0)

  if (to.name !== 'login') {

    const parseJwt = (token) => {
      var base64Url = token.split('.')[1]
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
      return JSON.parse(jsonPayload)
    }

    var hasPermission = false;
    if (store.getters.accessToken) {
      const parsedToken = parseJwt(store.getters.accessToken)
      if (Date.now() < parsedToken.exp * 1000 && await store.getters.userRole !== 'guest' && store.getters.isAuthenticated) {
        // const refreshToken = this.$store.getters.refreshToken ? this.$store.getters.refreshToken : ''
        // this.$store.dispatch('refreshAccessToken', refreshToken)
        hasPermission = true
      }
    }

    if (!hasPermission) {
      store.commit('CONNECT_USER', false)
      store.commit('SET_ACCESS_TOKEN', null)
      store.commit('SET_REFRESH_TOKEN', null)
      store.commit('SET_XCSRF_TOKEN', null)
      store.commit('SET_USER', {
        roles: [
          'guest'
        ]
      })

      // Les cookies sont effacés
      Object.keys(Cookies.get()).map(cookieName => Cookies.remove(cookieName))

      next({
        path: '/login',
        query: {redirect: to.fullPath}
      })
    }
  }

  // Code trouvé sur ce site :
  // https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
  // Permet d'afficher les titres des pages et de récupérer le titre de la page
  // parente si jamais la page en cours n'en a pas.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  if (nearestWithTitle) document.title = nearestWithTitle.meta.title

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  if (!nearestWithMeta) return next()

  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })

    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  })
    .forEach(tag => document.head.appendChild(tag))
  next()
})

export default router
