<template>
  <card>
    <template slot="header">
      <icon
        icon="indices-eco"
        size="lg" />
      <h2>Indices macro économiques</h2>
    </template>

    <MarketIndexes />

    <template slot="footer">
      <button
        class="btn btn-small btn-outline--primary"
        @click="handleRedirect">Voir l'ensemble des indices</button>
    </template>
  </card>
</template>

<script>
import Card from '@/components/commons/Card.vue'
import MarketIndexes from '@/components/amcharts/MarketIndexes.vue'

export default {
  components: {
    Card,
    MarketIndexes
  },

  data () {
    return {
      chart: ''
    }
  },

  methods: {
    handleRedirect () {
      this.$router.push({ name: 'indices-listing' })
    }
  }
}
</script>
